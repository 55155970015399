import React from 'react';
import {Col} from 'react-bootstrap';

export default function Footer() {
    return (
        <div className="footer mt-auto py-3 bg-white text-center">
            <Col md={12} sm={12} className=" text-center">
                <div className="container-fluid pt-0 ht-100p">
                    Copyright © 2025{" "}
                    {/*<Link to="https://uneight.com" className="text-primary">*/}
                    {/*    Edge Drive*/}
                    {/*</Link> All rights reserved*/}
                </div>
            </Col>
        </div>
    );
}




